@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

/**************************[ BASE ]**************************/
body {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #333;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 14px;
	line-height: 24px;
}
h1 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
	font-weight:bold;
	color:#a80000;
}
h2 {
	font-size:16px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:20px;
	font-family: 'Open Sans', sans-serif;
}
h3 {
	font-size:16px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Open Sans', sans-serif;
}
h4, h6, .footer .heading {
	font-weight:600;
	font-size:18px;
	font-family: 'Open Sans', sans-serif;
}

.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Open Sans', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#a80000;
}

/**************************[ HEADER ]**************************/
header#header {
	box-shadow: 0 3px 45px rgb(0 0 0 / 15%);
	-webkit-box-shaodw: 0 3px 45px rgb(0 0 0 / 15%);
	position: fixed;
	width: 100%;
	z-index: 9999;
}


.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}


.header-top-container .header-top, .header-top-container .header-top .grid-full.module-header-multi {
	line-height: normal;
	width: 100%;
	margin: 0;
	max-width: none;
}
.std p.welcome-msg {
	margin: 0;
	line-height: 35px;
}
.header .userCode, .header .userName, .header .userSwitch {
	line-height: 35px;
}
/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #595959;
	font-family: 'Open Sans', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #595959;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #a80000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #a80000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	font-weight: 700;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	font-size: 20px;
}
#root-wrapper span.fa {
	font-size: 20px;
}

/* Menu */
.links > li#li_link_register {
	background-color: #595959;
	color: #fff;
}
.links > li#li_link_register + li {
	background-color: #a80000;
	color: #fff;
}
.links > li#li_link_register a, .links > li#li_link_register + li a {
	color: #fff;
}
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #595959;
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
}

#nav {
	margin-top: 0;
	margin-bottom: 0;
	display: flex;
	justify-content: right;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #a80000;
}

.nav-search:not(.fly-out) input#search {
	display: none;
}
.nav-search:not(.fly-out) button.button.button-search {
	line-height: 84px;
}
.nav-search.fly-out {
    display: none;
}
.nav-search.fly-out {
	position: fixed;
	display: block !important;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 9999;
	width: 100% !important;
	background-color: #fff;
	margin: 0;
}
.nav-search.fly-out .module-search {
	transform: translateY(-50%);
    top: 50%;
}
.nav-search.fly-out .form-search {
	position: relative;
	padding: 10px;
}
.nav-search.fly-out .form-search .input-text {
	width: 100%;
	max-width: 100%;
	display: block;
	border: none;
	padding-right: 70px;
	height: 109px;
	text-align: center;
	font-size: 80px;
	font-weight: bold;
	text-transform: uppercase;
}
.nav-search.fly-out .form-search .button {
	color: #aaa;
	top: 0 !important;
	right: 10px !important;
	position: absolute !important;
	margin: 0;
	padding: 0;
	font-size: inherit;
}
.nav-search.fly-out .form-search .button .fa {
	margin: 0 !important;
}
.nav-search.fly-out .form-search .button-search {
	right: 45px !important;
}
.nav-search.fly-out .module-search .form-search .button-search .fa {
	font-size: 50px;
	line-height: 109px;
}
.nav-search:not(.fly-out) .module-search .form-search .button-search .fa {
	font-size: 18px;
}
.nav-search.fly-out #inline-search-results {
	top: 50px;
}
.nav-search.fly-out .form-search .button.button-close {
	display: block;
	top: -30vh !important;
    right: 2vw !important;
	font-size: 25px;
	color: #333;
}
.nav-search .form-search .button-close {
    display: none;
}
#nav .nav-panel--dropdown a {
	color: #595959;
	font-size: 12px;
	padding: 0 6px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 12px;
}
#nav .nav-panel--dropdown a:hover {
	background-color: #a80000;
	color: #fff;
}
.mobnav-trigger.active {
	background-color: #a80000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #595959;
}
#nav .nav-item--home {
	display: none;
}
#nav .nav-item--home .fa-home {
	color: #a80000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #a80000;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #a80000;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #fff;
	border-right-color: #fff;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #a80000 !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #595959 !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}
.mobile-header-container {
	margin: 0;
	overflow: hidden;
	padding: 12px 0;
}
.mm-menu, .mm-panels, .mm-navbar, .mm-navbar, .mm-panel {
	background-color: #a80000 !important;
	color: #fff !important;
}
.mm-menu a, .mm-menu a:active, .mm-menu a:hover, .mm-menu a:link, .mm-menu a:visited {
	color: #fff !important;
}
.mm-btn:after, .mm-btn:before, .mm-listitem {
	border-color: #fff !important;
}
.mm-listitem:after {
	opacity: 0.5;
}
.mm-menu a, .mm-menu a span {
	font-size: 18px;
}
.mm-menu a.mm-navbar__title span {
	font-weight: bold;
}
.mobile-menu-column {
	text-align: right;
}
header#header .mobile-menu-column.grid-column-wrapper.grid12-3, header#header .mobile-menu-column.grid-column-wrapper.grid12-3 .grid-full {
	overflow: hidden;
}
.nav-regular .nav-item.level0 > a::after {
	-ms-transition: -webkit-transform .3s ease-out,border-color .3s ease-out;
    -webkit-transition: -webkit-transform .3s ease-out,border-color .3s ease-out;
    transition: transform .3s ease-out,border-color .3s ease-out;
    transition-property: transform, border-color;
    transition-duration: 0.3s, 0.3s;
    transition-timing-function: ease-out, ease-out;
    transition-delay: 0s, 0s;
    position: absolute;
    display: block;
    bottom: 25px;
    left: 0;

    -ms-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    border-top: 2px solid transparent;
    content: '';
    padding-bottom: inherit;
} 
.nav-regular .nav-item.level0:hover > a::after {
	backface-visibility: hidden;
    -ms-transform: scaleX(0.75);
    -webkit-transform: scaleX(0.75);
    transform: scaleX(0.75);
	width: 100%;
	border-top-color: #a80000;
}
@media only screen and (min-width: 960px) {
	header#header.floating .header-top-container {
		display: none;
	}
}
@media only screen and (max-width: 959px) {
	.header .logo {
		max-height: none;
		max-width: 149px;
	}
	.header .logo img {
		width: 100%;
	}
	#mini-cart .hide-below-960 {
		display: block !important;
	}
	.nav-search.fly-out .form-search .input-text {
		padding-right: 50px;
		height: 43px;
		font-size: 28px;
	}
	.nav-search.fly-out .form-search .button-search {
		right: 25px !important;
	}
	.nav-search.fly-out .module-search .form-search .button-search .fa {
		line-height: 43px;
		font-size: 30px;
		color: #595959;
	}
	.dropdown-toggle *, .header span, .header span.cart-total {
		color: #595959;
	}
	.nav-search.fly-out .form-search .button.button-close {
		top: -45vh !important;
	}
	.nav-search:not(.fly-out) button.button.button-search, #mini-cart {
		line-height: 45px;
	}
	
	#mini-cart .empty, #mini-cart .caret {
		display: none;
	}
	#root-wrapper #header span.fa {
		padding: 0 0.5em;
		line-height: 45px;
	}
	.header .userCode {
		margin-right: 0;
	}
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
	header#header .mobile-menu-column.grid-column-wrapper.grid12-3 {
		width: 14.66% !important;
	}
}
@media only screen and (max-width: 767px) {
	header#header .grid-column-wrapper.grid12-12 {
		width: 98% !important;
	}
	header#header .grid-column-wrapper.grid12-11 {
		width: 89.67% !important;
	}
	header#header .grid-column-wrapper.grid12-10 {
		width: 81.33% !important;
	}
	header#header .grid-column-wrapper.grid12-9 {
		width: 73% !important;
	}
	header#header .grid-column-wrapper.grid12-8 {
		width: 64.67% !important;
	}
	header#header .grid-column-wrapper.grid12-7 {
		width: 56.33% !important;
	}
	header#header .grid-column-wrapper.grid12-6 {
		width: 48% !important;
	}
	header#header .grid-column-wrapper.grid12-5 {
		width: 39.67% !important;
	}
	header#header .grid-column-wrapper.grid12-4 {
		width: 31.33% !important;
	}
	header#header .grid-column-wrapper.grid12-3 {
		width: 23% !important;
	}
	header#header .grid-column-wrapper.grid12-2 {
		width: 14.66% !important;
	}
	header#header .grid-column-wrapper.grid12-1:not(.fly-out) {
		width: 6.33% !important;
	}
}
/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 32px;
	color: #a80000;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #a80000;
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #595959 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #43266d;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #43266d;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}
@media only screen and (min-width: 960px) {
	.show-below-960, .hide-above-960 {
		display: none !important;
	}
	header#header + .main-container {
		padding-top: 119px;
	}
	header#header.floating + .main-container {
		padding-top: 84px;
	}
}
@media only screen and (max-width: 959px) {
	.hide-below-960 {
		display: none !important;
	}
	header#header + .main-container {
		padding-top: 69px;
	}
}
/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
#footer, .footer-container, .footer-container2, .footer-top-container, .footer-primary.footer.container, .footer-top, .footer-bottom-container
{
	background-color: #e0e0e0;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	color: #595959;
	clear: left;
	border-top: 1px solid #bbb;
}
.footer-logo-column * {
	font-size: 20px;
}
.footer-logo-column p {
	margin-bottom: 10px;
}
.footer-social-column a {
	margin-right: 23px;
}
.footer-social-column i.fa {
	font-size: 25px;
}
@media only screen and (max-width: 959px) {
	.footer-logo-column img {
		max-width: 250px;
	}
}
@media only screen and (min-width: 767px) and (max-width: 959px) {
	#footer div[id^="column"] {
		width: 48% !important;
	}
}

/************************** MICS **************************/


